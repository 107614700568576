@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.video-page {
  position: relative;
}

.video-page__video {
  background: var(--video-page-video-background);
}

.video-page__main {
  position: relative;
  display: flex;
  min-height: calc(100vw / var(--video-page-ratio));

  @include h.breakpoint-l {
    min-height: unset;
  }
}

.video-page__player {
  background: var(--video-page-player-background);
  position: relative;
  flex-grow: 1;

  @include h.breakpoint-l {
    flex-grow: 0;
    width: calc(100% - 465px);
  }
}

.video-page__drawer {
  position: relative;
  display: none;
  overflow: auto;
  flex-basis: var(--video-page-drawer-flex-basis);
  height: var(--video-page-playlist-open-video-height);
  margin: var(--video-page-drawer-margin);

  @include h.breakpoint-l {
    display: block;
    overflow-x: visible;
    height: var(--video-page-playlist-open-video-height);
  }
}

.video-page__carousel {
  @include h.breakpoint-l {
    display: none;
  }
}

.video-page__CTA {
  &--desktop {
    display: none;

    @include h.breakpoint-l {
      display: block;
    }
  }

  &--mobile {
    display: none;

    @include h.breakpoint-m-max {
      display: block;
    }
  }
}

:global(.pkg) {
  @each $heading in (h1, h2, h3, h4, h5, h6) {

    #{$heading}>a,
    a>#{heading} {
      transition: opacity 0.25s ease-in-out;
    }

    #{$heading}>a:visited,
    a:visited>#{heading} {
      opacity: 1;
    }

    #{$heading}>a:hover,
    a:hover>#{heading} {
      opacity: 0.7;
    }

    #{$heading}>a:active,
    a:active>#{heading} {
      opacity: 0.5;
    }
  }
}

body.today {
  .video-page__carousel {
    position: relative;
    margin: h.rem(50px 0 50px 20px);

    @include h.breakpoint-m {
      margin: h.rem(48px 30px);
    }
  }

  .video-page__main {
    margin: var(--video-page-main-margin);
  }

  .video-page__player {
    margin: 0 auto;
    width: 100%;
  }

  .video-page__drawer {
    flex-grow: 0;
    flex-shrink: 0;
  }
}
