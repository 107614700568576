@use 'assets/styles/utils/helper' as h;

:root {
  --playlist-drawer-info-justify-content: initial;
  --playlist-drawer-title-font-family: var(--default-headline-font-family);
  --playlist-drawer-title-font-size: 18px;
  --playlist-drawer-title-font-leading: 20px;
  --playlist-drawer-title-margin: 0 0 #{h.rem(8px)};
  --playlist-drawer-title-opacity--hover: 1;
  --playlist-drawer-eyebrow-font-family: var(--founders-cond);
  --playlist-drawer-eyebrow-font-size: 13px;
  --playlist-drawer-eyebrow-line-height: 15px;
  --playlist-drawer-up-next-background-color: var(--default-color);
  --playlist-drawer-up-next-text-color: var(--white);
  --playlist-drawer-text-color: var(--white);
  --playlist-drawer-live-flag-text-color: var(--white);
  --playlist-drawer-live-flag-background-color: rgba(0, 0, 0, 0.5);
  --playlist-drawer-live-flag-dot-background-color: var(--default-color);
  --playlist-drawer-info-text-color: var(--white);
  --playlist-drawer-info-background-color: var(--grey-70);
  --playlist-drawer-info-background-color--hover: var(--grey-60);
  --playlist-drawer-video-background-gradient: linear-gradient(to top, var(--black), rgba(0, 0, 0, 0));
  --playlist-drawer-up-next-width: unset;
  --playlist-drawer-up-next-padding: #{h.rem(12px 20px)};
  --playlist-drawer-duration-opacity: 0.5;
  --playlist-drawer-active-font-family: var(--founders-mono);
  --playlist-drawer-active-background: var(--default-color);
  --playlist-drawer-active-opacity: 0.7;
  --playlist-drawer-active-z-index: 10;

  @include h.breakpoint-l {
    --playlist-drawer-title-font-size: 16px;
    --playlist-drawer-title-margin: 0 0 #{h.rem(10px)};
    --playlist-drawer-video-after-background-color: var(--white);
    --playlist-drawer-thumbnail-width: #{h.rem(224px)};
    --playlist-drawer-video-height: #{h.rem(112px)};
  }
}

body.news {
  --playlist-drawer-live-flag-dot-background-color: var(--red-50);
  --playlist-drawer-active-opacity: 1;
  --playlist-drawer-active-z-index: 2;
  --playlist-drawer-active-background: rgba(48, 98, 255, 0.7);
}

body.better {
  --playlist-drawer-live-flag-dot-background-color: var(--red-30);
}

body.msnbc {
  --playlist-drawer-up-next-background-color: var(--yellow-40);
  --playlist-drawer-up-next-text-color: var(--black);
  --playlist-drawer-text-color: var(--black);
  --playlist-drawer-live-flag-dot-background-color: var(--red-30);
  --playlist-drawer-active-opacity: 1;
  --playlist-drawer-active-z-index: 2;
  --playlist-drawer-active-background: rgba(255, 190, 3, 0.7);
}

body.today {
  --playlist-drawer-info-justify-content: center;
  --playlist-drawer-title-font-family: var(--secondary-font);
  --playlist-drawer-title-font-leading: var(--leading-150);
  --playlist-drawer-title-font-size: var(--text-16);
  --playlist-drawer-title-margin: 0;
  --playlist-drawer-title-max-width: #{h.rem(240px)};
  --playlist-drawer-title-opacity--hover: 1;
  --playlist-drawer-title-hover-color: var(--accent-orange);
  --playlist-drawer-video-background-gradient: none;
  --playlist-drawer-video-after-background-color: none;
  --playlist-drawer-up-next-background-color: rgba(0, 0, 0, 0.8);
  --playlist-drawer-eyebrow-font-family: var(--secondary-font);
  --playlist-drawer-eyebrow-font-size: var(--text-12);
  --playlist-drawer-eyebrow-line-height: var(--leading-150);
  --playlist-drawer-up-next-padding: #{h.rem(12px 14px 10px)};
  --playlist-drawer-up-next-width: #{h.rem(80px)};
  --playlist-drawer-info-background-color: transparent;
  --playlist-drawer-info-background-color--hover: transparent;
  --playlist-drawer-duration-opacity: 1;
  --playlist-drawer-active-font-family: var(--secondary-font);
  --playlist-drawer-active-background: rgba(0, 0, 0, 0.5);
  --playlist-drawer-active-opacity: 1;

  @include h.breakpoint-m {
    --playlist-drawer-title-max-width: none;
  }

  @include h.breakpoint-l {
    --playlist-drawer-title-font-size: var(--text-16);
    --playlist-drawer-title-margin: 0;
    --playlist-drawer-video-height: #{h.rem(100px)};
    --playlist-drawer-video-background-gradient: none;
    --playlist-drawer-thumbnail-width: #{h.rem(200px)};
  }
}

body.think {
  --playlist-drawer-title-font-size: 13.5px;

  @include h.breakpoint-l {
    --playlist-drawer-title-font-size: 14px;
  }
}
