@use 'assets/styles/utils/helper' as h;

.playlist {
  margin-bottom: calc(((100% + 40px) / 4) + 60px);

  @include h.breakpoint-m {
    display: flex;
  }

  @include h.breakpoint-l {
    margin-bottom: h.rem(210px);

    .info:hover {
      transform: translateX(10px);
    }

    .title:hover {
      color: var(--default-color);
    }

    .image:hover {
      @include h.breakpoint-l {
        &:nth-child(2) {
          transform: scale(2) translate(-14px, -77px);
        }

        &:nth-child(3) {
          transform: scale(2) translate(-119px, -74px);
        }
      }

      @include h.breakpoint-x {
        &:nth-child(2) {
          transform: scale(2) translate(-14px, -83px);
        }

        &:nth-child(3) {
          transform: scale(2) translate(-126px, -80px);
        }
      }
    }
  }

  &:nth-child(even) {
    @include h.breakpoint-l {
      .info:hover {
        transform: translateX(-10px);
      }

      .image:hover {
        &:nth-child(2) {
          transform: scale(2) translate(122px, -77px);
        }

        &:nth-child(3) {
          transform: scale(2) translate(12px, -74px);
        }
      }
    }

    @include h.breakpoint-x {
      .image:hover {
        &:nth-child(2) {
          transform: scale(2) translate(130px, -83px);

          &:nth-child(3) {
            transform: scale(2) translate(12px, -80px);
          }
        }
      }
    }
  }
}

.info {
  position: relative;
  z-index: 2;

  @include h.breakpoint-m {
    flex-basis: h.rem(260px);
    flex-grow: 1;
    align-self: flex-end;
    order: 1;
  }

  @include h.breakpoint-l {
    flex-basis: h.rem(440px);
    flex-grow: 0;
    margin-bottom: h.rem(20px);
    transition: transform 0.3s;
    cursor: pointer;
  }

  @include h.breakpoint-l {
    flex-basis: h.rem(460px);
  }
}

.title {
  font-size: h.rem(30px);
  line-height: 1;
  font-family: var(--founders-cond);
  font-weight: normal;
  margin: 0;
  transition: color 0.3s;

  @include h.breakpoint-m {
    font-size: h.rem(40px);
  }

  @include h.breakpoint-l {
    font-size: h.rem(50px);
  }

  @include h.breakpoint-x {
    font-size: h.rem(60px);
  }
}

.image {
  display: block;
  position: relative;

  @include h.breakpoint-l {
    cursor: pointer;
    transition: transform 0.3s;
  }

  img {
    vertical-align: top;
  }

  &::before {
    @include h.breakpoint-l {
      background-color: var(--default-color);
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      opacity: 0;
      transition: opacity 0.3s;
      pointer-events: none;
    }
  }

  &::after {
    @include h.breakpoint-l {
      content: '';
      position: absolute;
      top: calc(50% - 8px);
      left: calc(50% - 10px);
      display: block;
      border-left: 20px solid var(--white);
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      opacity: 0;
      transition: opacity 0.3s;
      pointer-events: none;
    }
  }

  &:first-child {
    width: calc(100% + 40px);
    z-index: 3;

    @include h.breakpoint-m {
      width: auto;
    }

    &::after {
      @include h.breakpoint-l {
        border-left: 40px solid var(--white);
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        top: calc(50% - 12px);
      }
    }
  }

  &:nth-child(n + 2) {
    width: calc(50vw - 20px);
    position: absolute;
    top: 100%;

    @include h.breakpoint-m {
      width: 50%;
    }
  }

  &:nth-child(2) {
    left: -40px;
    z-index: 2;

    @include h.breakpoint-m {
      left: 33%;
    }
  }

  &:nth-child(3) {
    left: calc(50vw - 60px);
    z-index: 1;

    @include h.breakpoint-m {
      right: -33%;
      left: auto;
    }
  }

  &:hover {
    @include h.breakpoint-l {
      &::before {
        opacity: 0.4;
      }

      &::after {
        opacity: 1;
      }
    }
  }

  a {
    display: block;
  }
}

.count {
  font-family: var(--founders-cond);
  font-weight: normal;
  font-size: h.rem(30px);
  line-height: 1;
  color: rgba(0, 0, 0, 0.2);
  position: relative;
  margin-bottom: h.rem(20px);

  @include h.breakpoint-m {
    font-size: h.rem(40px);
  }

  @include h.breakpoint-l {
    font-size: h.rem(50px);
  }

  @include h.breakpoint-x {
    font-size: h.rem(60px);
  }

  &::before {
    background-color: var(--default-color);
    content: '';
    width: h.rem(50px);
    height: h.rem(25px);
    margin-right: h.rem(10px);
    display: inline-block;
    position: relative;
    top: h.rem(3px);

    @include h.breakpoint-m {
      width: h.rem(60px);
      height: h.rem(30px);
    }

    @include h.breakpoint-l {
      width: h.rem(70px);
      height: h.rem(35px);
    }

    @include h.breakpoint-x {
      width: h.rem(80px);
      height: h.rem(40px);
    }
  }

  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: h.rem(6px) solid var(--white);
    border-top: h.rem(3px) solid transparent;
    border-bottom: h.rem(3px) solid transparent;
    position: absolute;
    left: h.rem(23px);
    top: h.rem(13px);

    @include h.breakpoint-m {
      left: h.rem(28px);
      top: h.rem(19px);
    }

    @include h.breakpoint-l {
      border-left: h.rem(8px) solid var(--white);
      border-top: h.rem(4px) solid transparent;
      border-bottom: h.rem(4px) solid transparent;
      left: h.rem(33px);
      top: h.rem(24px);
    }

    @include h.breakpoint-x {
      left: h.rem(38px);
      top: h.rem(31px);
    }
  }
}

.sponsor {
  font-family: var(--founders-mono);
  font-weight: normal;
  font-size: h.rem(12px);
  line-height: 1;
  color: var(--grey-60);
  letter-spacing: -0.2px;
  margin: h.rem(20px 0);
}

.images {
  position: relative;
  z-index: 1;

  @include h.breakpoint-m {
    order: 0;
    flex-basis: h.rem(383px);
    flex-grow: 1;
    margin-right: h.rem(30px);
  }

  @include h.breakpoint-l {
    flex-basis: h.rem(440px);
    flex-grow: 0;
    margin-right: h.rem(40px);
  }

  @include h.breakpoint-x {
    flex-basis: h.rem(460px);
  }
}

.playlist:has(.info:hover) {
  @include h.breakpoint-l {
    .info {
      transform: translateX(10px);
    }

    .title {
      color: var(--default-color);
    }
  }

  .image {
    @include h.breakpoint-l {
      &:nth-child(2) {
        transform: scale(2) translate(-14px, -77px);
      }

      &:nth-child(3) {
        transform: scale(2) translate(-119px, -74px);
      }
    }

    @include h.breakpoint-x {
      &:nth-child(2) {
        transform: scale(2) translate(-14px, -83px);
      }

      &:nth-child(3) {
        transform: scale(2) translate(-126px, -80px);
      }
    }
  }

  &:nth-child(even) {
    @include h.breakpoint-l {
      .info {
        transform: translateX(-10px);
      }
    }

    .image {
      @include h.breakpoint-l {
        &:nth-child(2) {
          transform: scale(2) translate(122px, -77px);
        }

        &:nth-child(3) {
          transform: scale(2) translate(12px, -74px);
        }
      }

      @include h.breakpoint-x {
        &:nth-child(2) {
          transform: scale(2) translate(130px, -83px);

          &:nth-child(3) {
            transform: scale(2) translate(12px, -80px);
          }
        }
      }
    }
  }
}
