@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.playlister__content {
  margin-left: 3vw;

  @include h.breakpoint-m {
    margin: 0;
  }

  @include h.breakpoint-x {
    padding: 0 h.rem(100px);
  }
}

.playlister__title {
  font-family: var(--playlister-title-font-family);
  font-size: h.rem(60px);
  line-height: h.rem(90px);
  text-align: center;
  color: var(--playlister-title-color);
  width: calc(100% + 40px);
  margin: h.rem(40px 0 20px -40px);
  position: sticky;
  top: h.rem(100px);
  z-index: -1;

  @include h.breakpoint-m {
    font-size: h.rem(100px);
    width: auto;
    margin: h.rem(60px 0 0);
    top: h.rem(140px);
  }

  @include h.breakpoint-l {
    font-size: h.rem(150px);
  }

  @include h.breakpoint-x {
    font-size: h.rem(200px);
  }
}

.playlister__playlists {
  position: relative;
  z-index: 2;
}
