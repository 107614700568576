@use 'assets/styles/utils/helper' as h;

:root {
  --prev-button-display: none;
  --next-button-display: none;
  --prev-button-disabled-display: none;
  --next-button-disabled-display: none;
}

:global(.today) {
  --next-button-display: none;
  --prev-button-display: none;

  @include h.breakpoint-m {
    --next-button-display: flex;
    --prev-button-display: flex;
    --prev-button-disabled-display: none;
    --next-button-disabled-display: flex;
  }

  @include h.breakpoint-l {
    --next-button-display: flex;
    --prev-button-display: flex;
    --next-button-disabled-display: none;
  }
}
