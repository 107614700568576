@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.video-details__info {
  margin-top: var(--video-details-margin-top);
  margin-right: var(--video-details-margin-right);
  margin-left: var(--video-details-margin-left);
  padding-bottom: var(--video-details-padding-bottom);
  color: var(--video-details-info-color);
  flex: 1 1 auto;

  &.video-details__sponsored {
    border-top: var(--video-details-sponsored-border-top);
    margin-top: var(--video-details-sponsored-margin-top);
    padding-top: var(--video-details-sponsored-padding-top);

    &.video-details__category {
      font-weight: bold;
    }

    @include h.breakpoint-m {
      border-color: var(--video-details-sponsored-border-color);
      margin-top: h.rem(20px);
    }

    &::before {
      content: '';
      position: absolute;
      display: inline-block;
      vertical-align: middle;
      border-bottom: var(--video-details-sponsored-border-bottom);
      margin-bottom: h.rem(29px);
      right: 0;
      left: h.rem(20px);

      @include h.breakpoint-m {
        display: none;
      }
    }
  }

  .video-details__flag {
    align-items: center;
    color: var(--video-details-live-flag-default-background-color);
    font-family: var(--publico-txt);
    font-size: var(--text-20);
    font-style: normal;
    font-weight: normal;
    line-height: h.rem(20px);

    @include h.breakpoint-m {
      font-size: var(--text-24);
      line-height: var(--leading-125);
    }

    &-dot {
      background-color: var(--video-details-live-flag-active-background-color);
      margin: 0 var(--space-4) var(--space-4) 0;
    }
  }
}

.video-details__category-and-sponsor {
  flex-basis: 100%;
  position: relative;
  clear: both;

  .video-details__sponsored & {
    margin-top: h.rem(29px);

    @include h.breakpoint-m {
      margin-top: 0;
    }
  }

  @include h.breakpoint-m {
    flex-basis: h.rem(240px);
    margin-bottom: 0;
    margin-top: 0;
    margin-right: h.rem(40px);
  }
}

.video-details__category {
  font-family: var(--video-details-category-font-family);
  font-weight: var(--video-details-category-font-weight);
  font-size: h.rem(12px);
  line-height: var(--video-details-category-line-height);
  color: var(--video-details-category-color);
  text-transform: var(--video-details-category-text-transform);
  margin: 0 0 h.rem(8px);
  float: left;

  @include h.breakpoint-m {
    float: none;
  }

  .video-details__sponsored & {
    font-family: var(--video-details-category-font-family);
    font-weight: normal;
  }
}

.video-details__sponsor-top {
  width: h.rem(240px);
  float: left;
  border-top: var(--video-details-sponsor-top-border-top);
  border-bottom: var(--video-details-sponsor-top-border-bottom);
  margin-top: h.rem(10px);
  height: 57px;

  @include h.breakpoint-m {
    float: none;
    padding: h.rem(18px 0);
    border-bottom: none;
  }
}

.video-details__sponsor-more-link {
  font-family: var(--founders-mono);
  font-weight: normal;
  font-size: h.rem(12px);
  letter-spacing: h.rem(-0.5px);
  color: var(--grey-60);
  display: block;
  cursor: pointer;
}

.video-details__title-and-share-and-dek {
  flex-basis: 100%;
  display: var(--video-details-title-and-share-and-dek-display);

  @include h.breakpoint-m {
    flex-basis: h.rem(418px);
    flex-grow: 1;
  }
}

.video-details__duration {
  display: none;
}

.video-details__title-and-share {
  @include h.breakpoint-l {
    flex-basis: h.rem(380px);
    margin-right: h.rem(40px);
  }
}

.video-details__social-share-menu {
  margin: var(--video-details-social-share-menu-margin);
}

.video-details__title {
  font-family: var(--video-details-title-font-family);
  font-size: var(--video-details-title-font-size);
  line-height: var(--video-details-title-line-height);
  font-weight: var(--video-details-title-font-weight);
  margin: 0;
  color: var(--video-details-title-color);
}

.video-details__dekWrapper {
  display: var(--video-details-dek-display);
  flex-direction: column;
  position: relative;

  @include h.breakpoint-m {
    height: auto;
    overflow: var(--video-details-dek-overflow);
  }

  @include h.breakpoint-l {
    flex-basis: h.rem(300px);
    flex-grow: 1;
    margin-left: 0;
    margin-top: 0;
  }
}

.video-details__dek {
  font-family: var(--video-details-dek-font-family);
  font-size: var(--video-details-dek-font-size);
  line-height: var(--video-details-dek-line-height);
  color: var(--video-details-dek-color);
  display: var(--video-details-dek-display);
  flex-direction: column;
  margin-bottom: 0;
  height: var(--video-details-dek-height);
  overflow: hidden;
  position: relative;

  &__social-share-menu {
    display: none;

    @include h.breakpoint-l {
      margin: h.rem(13px 0);
      display: block;
      order: 1;
    }
  }

  & .video-details__dek-text {
    color: var(--video-details-dek-text-color);

    .video-details__dek-description {
      -webkit-font-smoothing: var(--video-details-dek-description-font-smoothing);
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: h.rem(50px);
      background-image: var(--video-details-dek-background-image);

      @include h.breakpoint-m {
        content: none;
      }
    }
  }

  &.video-details__dek-open {
    height: auto;
    overflow: auto;

    &::before {
      content: none;
    }

    & .video-details__dek-text::before {
      height: 0;
    }
  }

  @include h.breakpoint-m {
    height: auto;
    overflow: var(--video-details-dek-overflow);
  }

  @include h.breakpoint-l {
    flex-basis: h.rem(300px);
    flex-grow: 1;
    margin-left: 0;
    margin-top: 0;
  }

  .video-details__by-line {
    font-family: var(--video-details-by-line-font-family);
    font-weight: var(--video-details-by-line-font-weight);
    font-size: var(--video-details-by-line-font-size);
    line-height: var(--video-details-by-line-line-height);
    letter-spacing: var(--video-details-by-line-letter-spacing);
    color: var(--video-details-by-line-color);
    display: block;
    margin: var(--video-details-by-line-margin);
  }
}

.video-details__social-container {
  display: flex;
  margin: var(--video-details-social-container-margin);
  flex-direction: var(--video-details-social-container-flex-direction);
  justify-content: var(--video-details-social-container-justify-content);

  .bookmark-button__container {
    flex: var(--video-details-bookmark-button-flex);
  }

  &__social-share-menu {
    display: flex;
    align-items: center;

    @include h.breakpoint-l {
      display: none;
    }
  }
}

.video-details__dek-read-more {
  font-family: var(--video-details-dek-read-more-font-family);
  font-size: var(--video-details-dek-read-more-font-size);
  line-height: var(--video-details-dek-read-more-line-height);
  text-transform: uppercase;
  text-align: var(--video-details-dek-read-more-text-align);
  background-color: var(--video-details-dek-read-more-background-color);
  color: var(--video-details-dek-read-more-color);
  width: 100%;
  margin-bottom: var(--video-details-dek-read-more-margin-bottom);
  border: 0;
  outline: none;

  @include h.breakpoint-m {
    display: none;
  }

  &::before {
    background-color: var(--video-details-dek-read-more-before-background-color);
    content: '';
    display: var(--video-details-dek-read-more-before-display);
    width: h.rem(40px);
    height: h.rem(2px);
    margin: h.rem(0 10px 2px 0);
  }
}

.video-details__sponsor-more {
  font-family: var(--publico-txt);
  font-weight: normal;
  font-size: h.rem(12px);
  line-height: h.rem(20px);
  background-color: var(--video-details-sponsor-more-background-color);
  color: var(--white);
  padding: h.rem(30px);
  display: none;
  position: absolute;
  width: h.rem(280px);
  z-index: 1;
  top: h.rem(30px);
  right: 0;

  &::before {
    width: 0;
    height: 0;
    content: ' ';
    border-style: solid;
    border-width: 0 0 10px 10px;
    border-color: var(--video-details-sponsor-more-border-color);
    position: absolute;
    top: h.rem(-10px);
    right: h.rem(40px);
  }

  @include h.breakpoint-m {
    top: h.rem(-100px);
    right: h.rem(-170px);

    &::before {
      border-width: 0 0 20px 20px;
      top: h.rem(142px);
      left: h.rem(-20px);
    }
  }

  &.video-details__sponsor-more-selected {
    display: block;
  }

  .video-details__sponsor-more-close {
    position: absolute;
    top: h.rem(10px);
    right: h.rem(10px);
    cursor: pointer;
  }
}

.video-details__no-sponsor {
  .video-details__sponsor-top,
  .video-details__sponsor-more {
    display: none;
  }
}

.video-details__related-article {
  &::before {
    content: ' ';
    display: block;
    border-top: 1px solid var(--grey-40);
    width: 110px;
    height: 0;
  }

  .video-details__related-article-title {
    display: none;
  }

  .video-details__article {
    display: flex;
    align-items: center;
  }

  .video-details__icon {
    font-size: h.rem(8px);
    margin-right: h.rem(6px);

    &:global(.icon-shop) {
      font-size: inherit;
    }
  }

  a:hover {
    opacity: var(--video-details-related-article-opacity);
  }
}

body.today {
  .video-details__dek-read-more {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;

    @include h.breakpoint-m {
      display: none;
    }

    &::after {
      display: block;
      content: '';
      width: 10px;
      height: 10px;
      border-top: 1px solid var(--primary-color);
      border-right: 1px solid var(--primary-color);
      transform: rotate(135deg);
      transform-origin: 50% 50%;
    }

    &.video-details__dek-open::after {
      transform: rotate(-45deg);
      transform-origin: 100% 50%;
    }
  }

  .video-details__related-article {
    order: -1;
    border-bottom: 1px solid var(--white);
    padding-bottom: h.rem(10px);
    margin-bottom: h.rem(14px);

    &::before {
      display: none;
    }

    .video-details__related-article-title {
      display: block;
      font-size: h.rem(20px);
      font-family: var(--secondary-font);
      font-weight: bold;
      margin-bottom: h.rem(12px);
    }

    .video-details__article {
      font-family: var(--secondary-font);
      font-size: h.rem(16px);
      line-height: var(--leading-150);
      color: var(--grey-20);
      text-decoration: underline;
      text-decoration-thickness: var(--video-details-article-text-dn-thickness);
      text-decoration-color: var(--primary-color);
      text-underline-offset: h.rem(4px);
      margin: h.rem(9px 0);

      @include h.breakpoint-m {
        line-height: h.rem(18px);
        letter-spacing: -0.03rem;
        margin: h.rem(12px 0);
      }

      &:hover {
        opacity: var(--video-details-related-article-opacity);
        color: var(--video-details-related-article-hover-color);
        text-decoration-color: var(--video-details-related-article-text-decoration-color);
      }
    }

    .video-details__icon {
      display: none;
    }
  }

  .video-details__dek-text {
    display: flex;
    flex-direction: column;
    margin-top: 0;

    .video-details__dek-description {
      order: 2;
    }
  }

  .video-details__title-and-share {
    display: flex;
    flex-direction: column;

    .share-list__use-colored-icons {
      margin-top: h.rem(12px);
      margin-bottom: h.rem(20px);
      order: 3;

      @include h.breakpoint-m {
        margin-top: h.rem(20px);
      }

      @include h.breakpoint-l {
        margin-bottom: h.rem(0);
      }
    }
  }

  @include h.breakpoint-m {
    .video-details__dek {
      width: 74%;
    }
  }

  @include h.breakpoint-l {
    .video-details__title-and-share-and-dek {
      display: flex;
      position: relative;
    }

    .video-details__dek-text {
      margin-top: 0;
    }

    .video-details__title-and-share {
      flex-basis: 33.33%;
      margin-right: h.rem(24px);
    }

    .video-details__dek {
      max-width: none;
      flex-basis: 66.66%;
    }
  }
}
