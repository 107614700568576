@use 'assets/styles/utils/helper' as h;

:global(.best-of-playlist) .bottomButton {
  border-color: var(--default-color);
  color: var(--default-color);
  margin: 0 auto;
  width: h.rem(200px);
  background-image: linear-gradient(35deg, var(--default-color) 50%, transparent 50%) !important;

  &:hover {
    color: var(--white);
  }

  @include h.breakpoint-l {
    display: none;
  }
}
