@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.playlistDrawer {
  position: relative;
  background: var(--black);
  // IE 11 Fix: For lazy loading to work
  // Need overflow scroll and height set
  // for lazyloading to correctly fire
  overflow: scroll;
  scroll-behavior: smooth;
  height: 100%;

  :global(body.today) & {
    scroll-snap-type: x mandatory;
    padding: 0;

    @include h.no-scrollbars();

    @include h.breakpoint-m {
      scroll-padding: 0 h.rem(50px);
    }

    @include h.breakpoint-l {
      scroll-snap-type: y mandatory;
      scroll-padding: h.rem(50px) 0;
    }
  }
}

.prevButton {
  display: var(--prev-button-display);
  padding: 0 h.rem(10px) 0 0;
  top: 0;
  left: 0;
  bottom: 0;

  &.disabled {
    display: var(--prev-button-disabled-display);
  }

  &::before {
    top: 0;
    right: 0;
    bottom: 0;
    border-width: 0 0 0 1px;
  }

  @include h.breakpoint-l {
    padding: 0 0 h.rem(10px);
    top: 0;
    right: 0;
    bottom: unset;
    left: 0;

    span {
      transform: rotate(90deg);
    }

    &::before {
      border-width: 1px 0 0;
      top: unset;
      left: 0;
      right: 0;
      bottom: 0;
      height: h.rem(10px);
      width: 100%;
    }
  }
}

.nextButton {
  display: var(--next-button-display);
  padding: 0 0 0 h.rem(10px);
  top: 0;
  right: 0;
  bottom: 0;

  span {
    transform: rotate(-180deg);
  }

  &.disabled {
    display: var(--next-button-disabled-display);
  }

  &::before {
    left: 0;
    border-width: 0 1px 0 0;
  }

  @include h.breakpoint-l {
    padding: h.rem(10px) 0 0;
    top: unset;
    right: 0;
    bottom: 0;
    left: 0;

    span {
      transform: rotate(-90deg);
    }

    &::before {
      border-width: 0 0 1px;
      top: 0;
      left: 0;
      right: 0;
      bottom: unset;
      height: h.rem(10px);
      width: 100%;
    }
  }
}

.prevButton,
.nextButton {
  justify-content: center;
  align-items: center;
  background: var(--black);
  border: none;
  position: absolute;
  height: 100%;
  z-index: 100;
  width: h.rem(50px);
  color: var(--primary-color);
  font-size: h.rem(24px);

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: h.rem(10px);
    background: var(--black);
    border-style: solid;
    border-color: var(--grey-50);
  }

  &:hover {
    background: var(--accent-orange);
    color: #102039;
  }

  @include h.breakpoint-l {
    width: 100%;
    height: h.rem(50px);

    &::before {
      width: 100%;
      height: h.rem(10px);
    }
  }
}

:global(.today) {
  .container {
    background: var(--white);
  }
}
