@use 'assets/styles/utils/helper' as h;

.video {
  overflow: hidden;
  position: relative;
}

.collapsed {
  .videos {
    display: none !important;
  }
}

.heading {
  font-family: var(--founders-cond);
  font-weight: var(--semibold);
  font-size: h.rem(15px);
  height: h.rem(80px);
  line-height: h.rem(80px);
  position: relative;
  color: var(--white);
  background: var(--black);
  padding: 0 h.rem(20px);
  margin: 0;
  display: block;

  :global(.news) &,
  :global(.today) &,
  :global(.msnbc) & {
    display: none;

    @include h.breakpoint-l {
      display: block;
    }
  }

  &::before {
    content: 'From –';
    opacity: 0.5;
    margin-right: 5px;

    .recommendations & {
      display: none;
    }
  }

  .collapsible & {
    cursor: pointer;

    &::after {
      content: '';
      width: h.rem(10px);
      height: h.rem(10px);
      border: 1px solid var(--grey-50);
      border-width: 0 1px 1px 0;
      position: absolute;
      top: h.rem(35px);
      transform: rotate(-135deg);
      right: h.rem(25px);
    }
  }

  .collapsed & {
    &::after {
      transform: rotate(45deg);
      top: h.rem(30px);
    }
  }
}

.playlist.horizontal {
  .videos {
    display: flex;
    overflow: auto;
  }

  .video {
    flex-basis: h.rem(240px);
    flex-shrink: 0;
  }
}

:global(.today) {
  .heading {
    background: var(--white);
    color: var(--black);
  }

  .playlist {
    display: inline-block;
    padding: 0 h.rem(20px) 0 0;

    @include h.breakpoint-m {
      padding: 0 h.rem(50px) 0 0;
    }

    @include h.breakpoint-l {
      padding: h.rem(14px 0);
    }

    .videos {
      display: flex;
      flex-direction: row;
      gap: h.rem(24px);

      @include h.breakpoint-m {
        gap: h.rem(20px);
      }

      @include h.breakpoint-l {
        flex-direction: column;
      }
    }

    .video {
      flex-basis: h.rem(260px);
      flex-shrink: 0;
      width: h.rem(260px);
      scroll-snap-align: start;

      @include h.breakpoint-m {
        flex-basis: h.rem(220px);
        width: h.rem(220px);
      }

      @include h.breakpoint-l {
        flex-basis: auto;
        width: auto;
      }
    }
  }
}
