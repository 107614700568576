@use 'assets/styles/utils/helper' as h;

.video-page {
  --video-page-ratio: calc(16 / 9);
  --video-page-playlist-open-video-height: calc((100vw - 465px) / var(--video-page-ratio));
  --video-page-video-background: var(--black);
  --video-page-player-background: var(--black);
  --video-page-drawer-flex-basis: #{h.rem(256px)};
  --video-page-drawer-margin: 0;

  @include h.breakpoint-l {
    --video-page-drawer-flex-basis: #{h.rem(465px)};
    --video-page-drawer-margin: 0 #{h.rem(-17px)} 0 0;
  }

  @include h.breakpoint-x {
    --video-page-drawer-flex-basis: #{h.rem(465px)};
    --video-page-drawer-margin: 0 #{h.rem(-17px)} 0 0;
  }

  @include h.breakpoint-xx {
    --video-page-drawer-flex-basis: #{h.rem(465px)};
    --video-page-drawer-margin: 0 #{h.rem(-17px)} 0 0;
  }

  body.telemundo & {
    --video-page-video-background: var(--neutral-30);
    --video-page-player-background: var(--neutral-30);
  }

  body.entretenimiento & {
    --video-page-video-background: var(--neutral-30);
    --video-page-player-background: var(--neutral-30);
  }

  body.shows & {
    --video-page-video-background: var(--neutral-30);
    --video-page-player-background: var(--neutral-30);
  }

  body.today & {
    --video-page-video-background: var(--black);
    --video-page-player-background: var(--black);
    --video-page-drawer-flex-basis: #{h.rem(360px)};
    --video-page-drawer-margin: #{h.rem(0 0 0 40px)};
    --video-page-main-margin: #{h.rem(0)};

    @include h.breakpoint-l {
      --video-page-playlist-open-video-height: calc((100vw - 440px) / var(--video-page-ratio));
      --video-page-drawer-flex-basis: #{h.rem(360px)};
      --video-page-main-margin: #{h.rem(0 40px 0 0)};
    }

    @include h.breakpoint-x {
      --video-page-playlist-open-video-height: calc((100vw - 480px) / var(--video-page-ratio));
      --video-page-drawer-flex-basis: #{h.rem(400px)};
    }

    @include h.breakpoint-xx {
      --video-page-playlist-open-video-height: calc((100vw - 700px) / var(--video-page-ratio));
      --video-page-drawer-flex-basis: #{h.rem(500px)};
      --video-page-main-margin: #{h.rem(0 80px)};
    }
  }
}
