@use 'assets/styles/utils/helper' as h;

.video-details__info {
  --video-details-margin-top: #{h.rem(20px)};
  --video-details-margin-left: #{h.rem(20px)};
  --video-details-margin-right: #{h.rem(20px)};
  --video-details-sponsored-border-color: var(--default-color);
  --video-details-info-color: var(--white);
  --video-details-info-border: 1px solid;
  --video-details-padding-bottom: 0;
  --video-details-sponsored-border-top: 1px solid rgba(var(--grey-20-rgb), .2);
  --video-details-sponsored-border-bottom: 1px solid rgba(var(--grey-20-rgb), .2);
  --video-details-sponsor-top-border-top: 1px solid rgba(var(--grey-20-rgb), .2);
  --video-details-sponsor-top-border-bottom: 1px solid rgba(var(--grey-20-rgb), .2);
  --video-details-title-font-family: var(--primary-font);
  --video-details-title-font-size: #{h.rem(20px)};
  --video-details-title-line-height: #{h.rem(20px)};
  --video-details-title-font-weight: normal;
  --video-details-title-and-share-and-dek-display: block;
  --video-details-dek-font-family: inherit;
  --video-details-dek-color: var(--white);
  --video-details-dek-display: block;
  --video-details-dek-height: #{h.rem(80px)};
  --video-details-dek-text-color: var(--grey-40);
  --video-details-dek-background-image: linear-gradient(to bottom, rgba(var(--black-rgb), 0) 0%, rgba(var(--black-rgb), 1) 100%);
  --video-details-dek-description-font-smoothing: antialiased;
  --video-details-by-line-color: var(--grey-50);
  --video-details-by-line-font-family: var(--founders-mono);
  --video-details-by-line-font-weight: normal;
  --video-details-by-line-font-size: #{h.rem(12px)};
  --video-details-by-line-line-height: #{h.rem(20px)};
  --video-details-by-line-letter-spacing: #{h.rem(-0.5px)};
  --video-details-by-line-margin: #{h.rem(10px) 0 0 0};
  --video-details-dek-read-more-color: var(--grey-30);
  --video-details-dek-read-more-text-align: right;
  --video-details-dek-read-more-background-color: var(--black);
  --video-details-dek-read-more-font-family: var(--founders-mono);
  --video-details-dek-read-more-font-size: #{h.rem(10px)};
  --video-details-dek-read-more-line-height: var(--leading-100);
  --video-details-dek-read-more-margin-bottom: #{h.rem(27px)};
  --video-details-dek-read-more-before-background-color: var(--default-color);
  --video-details-dek-read-more-before-display: inline-block;
  --video-details-sponsor-more-background-color: var(--grey-70);
  --video-details-sponsor-more-border-color: transparent transparent var(--grey-70) transparent;
  --video-details-category-color: none;
  --video-details-category-text-transform: uppercase;
  --video-details-category-font-family: var(--founders-mono);
  --video-details-category-font-weight: normal;
  --video-details-category-line-height: #{h.rem(12px)};
  --video-details-sponsored-margin-top: 0;
  --video-details-sponsored-padding-top: none;
  --video-details-info-background: none;
  --video-details-title-color: none;
  --video-details-related-article-opacity: 0.7;
  --video-details-article-text-dn-thickness: auto;
  --video-details-dek-font-size: #{h.rem(14px)};
  --video-details-dek-line-height: #{h.rem(21px)};
  --video-details-live-flag-default-background-color: var(--default-color);
  --video-details-live-flag-active-background-color: var(--default-color);
  --video-details-social-share-menu-margin: #{h.rem(16px)} 0 #{h.rem(40px)};
  --video-details-dek-description-letter-spacing: normal;
  --video-details-dek-overflow: auto;
  --video-details-social-container-margin: #{h.rem(10px 0 20px)};
  --video-details-social-container-flex-direction: unset;
  --video-details-social-container-justify-content: unset;
  --video-details-bookmark-button-flex: 1;

  @include h.breakpoint-m {
    --video-details-margin-left: 0;
    --video-details-margin-right: 0;
    --video-details-title-font-size: #{h.rem(32px)};
    --video-details-title-line-height: #{h.rem(32px)};
  }

  @include h.breakpoint-l {
    --video-details-title-and-share-and-dek-display: flex;
  }

  body.news & {
    --video-details-title-color: var(--white);
    --video-details-category-color: var(--white);
    --video-details-dek-read-more-before-background-color: #3062ff;
    --video-details-live-flag-default-background-color: var(--red-50);
    --video-details-live-flag-active-background-color: var(--red-50);

    @include h.breakpoint-m {
      --video-details-padding-bottom: #{h.rem(16px)};
    }
  }

  body.mach & {
    --video-details-sponsored-border-color: var(--white);
  }

  body.better & {
    --video-details-sponsored-border-color: var(--white);
    --video-details-live-flag-default-background-color: var(--red-30);
    --video-details-live-flag-active-background-color: var(--red-30);
  }

  body.globalcitizen & {
    --video-details-sponsored-border-color: var(--white);
  }

  body.msnbc & {
    --video-details-sponsored-border-color: var(--yellow-40);
    --video-details-title-color: var(--white);
    --video-details-category-color: var(--white);
    --video-details-live-flag-default-background-color: var(--red-30);
    --video-details-live-flag-active-background-color: var(--red-30);
    --video-details-social-share-menu-margin: #{h.rem(10px 0 0 0)};

    @include h.breakpoint-l {
      --video-details-padding-bottom: #{h.rem(16px)};
    }
  }

  body.think & {
    --video-details-sponsored-border-color: var(--white);
    --video-details-title-font-family: var(--publico-hed);
    --video-details-title-font-size: #{h.rem(20px)};

    @include h.breakpoint-m {
      --video-details-title-font-size: #{h.rem(24px)};
    }

    @include h.breakpoint-l {
      --video-details-padding-bottom: #{h.rem(16px)};
    }
  }

  body.deportes &,
  body.localstationdeportes &,
  body.noticias &,
  body.entretenimiento &,
  body.shows & {
    --video-details-title-color: var(--white);
    --video-details-category-color: var(--white);
    --video-details-dek-background-image: linear-gradient(to bottom, transparent, var(--black));
    --video-details-social-share-menu-margin: #{h.rem(10px 0 0 0)};

    @include h.breakpoint-l {
      --video-details-social-share-menu-margin: #{h.rem(10px 0 )};
    }
  }

  body.entretenimiento & {
    --video-details-sponsored-border-bottom: 1px solid var(--red-50);
    --video-details-sponsor-top-border-top: 1px solid var(--default-color);
    --video-details-sponsor-top-border-bottom: 1px solid var(--default-color);
    --video-details-title-font-weight: 600;
    --video-details-dek-color: var(--grey-60);
    --video-details-dek-text-color: var(--grey-60);
    --video-details-dek-background-image: linear-gradient(to bottom, rgba(var(--white-rgb), 0.01) 0%, rgba(var(--neutral-30-rgb), 1) 100%);
    --video-details-by-line-color: var(--grey-60);
    --video-details-category-color: var(--default-color);
    --video-details-sponsored-margin-top: 20px;
    --video-details-sponsored-padding-top: 20px;
    --video-details-title-color: var(--blue-60);
    --video-details-dek-read-more-color: var(--default-color);
    --video-details-dek-read-more-background-color: var(--neutral-30);
  }

  body.shows & {
    --video-details-sponsored-border-bottom: 1px solid var(--red-50);
    --video-details-sponsor-top-border-top: 1px solid var(--default-color);
    --video-details-sponsor-top-border-bottom: 1px solid var(--default-color);
    --video-details-title-font-weight: 600;
    --video-details-dek-color: var(--grey-60);
    --video-details-dek-text-color: var(--grey-60);
    --video-details-dek-background-image: linear-gradient(to bottom, rgba(var(--white-rgb), 0.01) 0%, rgba(var(--neutral-30-rgb), 1) 100%);
    --video-details-by-line-color: var(--grey-60);
    --video-details-category-color: var(--default-color);
    --video-details-sponsored-margin-top: 20px;
    --video-details-sponsored-padding-top: 20px;
    --video-details-title-color: var(--blue-60);
    --video-details-dek-read-more-color: var(--default-color);
    --video-details-dek-read-more-background-color: var(--neutral-30);
  }

  body.telemundo & {
    --video-details-sponsored-border-bottom: 1px solid var(--red-50);
    --video-details-sponsor-top-border-top: 1px solid var(--default-color);
    --video-details-sponsor-top-border-bottom: 1px solid var(--default-color);
    --video-details-title-font-weight: 600;
    --video-details-dek-color: var(--grey-60);
    --video-details-dek-text-color: var(--grey-60);
    --video-details-dek-background-image: linear-gradient(to bottom, rgba(var(--white-rgb) 0.01) 0%, rgba(var(-var(--neutral-30)), 1) 100%);
    --video-details-by-line-color: var(--grey-60);
    --video-details-category-color: var(--default-color);
    --video-details-sponsored-margin-top: 20px;
    --video-details-sponsored-padding-top: 20px;
    --video-details-title-color: var(--blue-60);
    --video-details-dek-read-more-color: var(--default-color);
    --video-details-dek-read-more-background-color: var(-var(--neutral-30));
    --video-details-social-share-menu-margin: #{h.rem(10px 0 0 0)};
  }

  body.today & {
    --video-details-sponsored-border-bottom: 1px solid rgba(var(--grey-60-rgb), .3);
    --video-details-sponsor-top-border-top: 1px solid var(--grey-20);
    --video-details-sponsor-top-border-bottom: 1px solid var(--grey-20);
    --video-details-sponsored-margin-top: 20px;
    --video-details-sponsored-padding-top: 20px;
    --video-details-info-background: linear-gradient(to right, var(--default-color), var(--gradient-value-2));
    --video-details-margin-top: #{h.rem(12px)};
    --video-details-padding-bottom: 0;
    --video-details-title-color: var(--white);
    --video-details-title-font-family: var(--primary-font);
    --video-details-title-font-size: #{h.rem(24px)};
    --video-details-title-line-height: #{h.rem(36px)};
    --video-details-dek-color: var(--white);
    --video-details-dek-display: flex;
    --video-details-dek-height: #{h.rem(80px)};
    --video-details-dek-font-family: var(--secondary-font);
    --video-details-dek-font-size: var(--text-16);
    --video-details-dek-line-height: var(--leading-150);
    --video-details-dek-text-color: var(--white);
    --video-details-dek-description-font-smoothing: antialiased;
    --video-details-dek-background-image: linear-gradient(to bottom, rgba(var(--black-rgb), 0.01) 0%, rgba(var(--black-rgb), 1) 100%);
    --video-details-by-line-color: var(--white);
    --video-details-category-color: var(--white);
    --video-details-category-font-family: var(--secondary-font);
    --video-details-category-font-weight: 700;
    --video-details-category-line-height: #{h.rem(18px)};
    --video-details-by-line-font-family: var(--secondary-font);
    --video-details-by-line-font-weight: 700;
    --video-details-by-line-font-size: #{h.rem(12px)};
    --video-details-by-line-line-height: #{h.rem(18px)};
    --video-details-by-line-letter-spacing: 0;
    --video-details-by-line-margin: 0;
    --video-details-dek-read-more-background-color: var(--black);
    --video-details-dek-read-more-before-display: none;
    --video-details-dek-read-more-text-align: center;
    --video-details-dek-read-more-color: var(--white);
    --video-details-dek-read-more-font-family: var(--secondary-font);
    --video-details-dek-read-more-font-size: #{h.rem(12px)};
    --video-details-dek-read-more-line-height: var(--leading-150);
    --video-details-dek-read-more-margin-bottom: #{h.rem(32px)};
    --video-details-social-share-menu-margin: #{h.rem(12px)} 0 #{h.rem(30px)};
    --video-details-dek-description-letter-spacing: -0.032em;
    --video-details-dek-overflow: visible;
    --video-details-social-container-margin: 0;
    --video-details-social-container-flex-direction: row-reverse;
    --video-details-social-container-justify-content: space-between;
    --video-details-bookmark-button-flex: 0 #{h.rem(175px)};
    --video-details-article-text-dn-thickness: 2px;
    --video-details-related-article-opacity: 1;
    --video-details-related-article-text-decoration-color: var(--accent-orange);
    --video-details-related-article-hover-color: var(--accent-orange);

    @include h.breakpoint-m {
      --video-details-margin-top: #{h.rem(40px)};
      --video-details-margin-left: #{h.rem(30px)};
      --video-details-margin-right: #{h.rem(30px)};
      --video-details-title-font-family: var(--primary-font);
      --video-details-title-font-size: #{h.rem(28px)};
      --video-details-title-line-height: #{h.rem(42px)};
      --video-details-social-share-menu-margin: #{h.rem(20px)} 0 0;
    }

    @include h.breakpoint-l {
      --video-details-margin-left: #{h.rem(40px)};
      --video-details-margin-right: #{h.rem(40px)};
      --video-details-title-and-share-and-dek-display: block;
      --video-details-social-container-flex-direction: unset;
      --video-details-social-container-justify-content: unset;
      --video-details-bookmark-button-flex: 1;
    }

    @include h.breakpoint-xx {
      --video-details-margin-left: #{h.rem(120px)};
      --video-details-margin-right: #{h.rem(120px)};
    }
  }
}
