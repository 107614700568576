@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.playlist-drawer__video {
  overflow: hidden;
  height: var(--playlist-drawer-video-height, 50vw);
  max-height: h.rem(270px);
  cursor: pointer;
  position: relative;

  @include h.breakpoint-l {
    display: flex;
    width: 100%;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: var(--playlist-drawer-video-background-gradient);

    @include h.breakpoint-l {
      content: '';
      height: 1px;
      width: 43px;
      opacity: 0.5;
      position: absolute;
      bottom: 0;
      right: auto;
      top: auto;
      left: 15em;
      z-index: 2;
      background-color: var(--playlist-drawer-video-after-background-color);
    }
  }

  .playlist-drawer__flag {
    align-items: center;
    color: var(--playlist-drawer-live-flag-text-color);
    font-family: var(--founders-mono);
    font-size: var(--text-10);
    line-height: h.rem(10px);
    font-style: normal;
    font-weight: normal;
    background-color: var(--playlist-drawer-live-flag-background-color);
    text-transform: uppercase;
    position: absolute;
    top: 0;
    right: 0;
    padding: h.rem(6px 8px);
    z-index: 12;

    &-dot {
      width: 4px;
      height: 4px;
      background-color: var(--playlist-drawer-live-flag-dot-background-color);
      margin: 0 var(--space-4) h.rem(2px) 0;
    }
  }
}

.playlist-drawer__thumbnail {
  position: relative;
  display: block;
  width: var(--playlist-drawer-thumbnail-width);
  aspect-ratio: 2 / 1;

  @include h.breakpoint-l {
    flex-shrink: 0;
  }
}

.playlist-drawer__title {
  font-family: var(--playlist-drawer-title-font-family);
  line-height: var(--playlist-drawer-title-font-leading);
  font-size: var(--playlist-drawer-title-font-size);
  margin: var(--playlist-drawer-title-margin);
  opacity: 1;
  transition: opacity 0.3s;
  max-width: var(--playlist-drawer-title-max-width, 85%);
  display: inline-block;
  padding-right: #{h.rem(15px)};

  :hover {
    color: var(--playlist-drawer-title-hover-color);
  }

  @include h.breakpoint-l {
    font-weight: normal;
    padding-right: 0;
    order: 1;
    max-width: none;
  }
}

.playlist-drawer__info {
  color: var(--playlist-drawer-info-text-color);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;

  @include h.breakpoint-l {
    width: 100%;
    background-color: var(--playlist-drawer-info-background-color);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: var(--playlist-drawer-info-justify-content);

    * {
      box-sizing: content-box;
    }
  }
}

.playlist-drawer__duration {
  font-family: var(--founders-mono);
  font-size: 10px;
  line-height: 10px;
  opacity: var(--playlist-drawer-duration-opacity);
  display: inline-block;
  text-align: right;

  @include h.breakpoint-l {
    order: 2;
    text-align: left;
    font-size: h.rem(11px);
  }
}

.playlist-drawer__up-next {
  background-color: var(--playlist-drawer-up-next-background-color);
  font-family: var(--playlist-drawer-eyebrow-font-family);
  font-size: var(--playlist-drawer-eyebrow-font-size);
  line-height: var(--playlist-drawer-eyebrow-line-height);
  color: var(--playlist-drawer-up-next-text-color);
  position: absolute;
  left: 0;
  top: 0;
  padding: var(--playlist-drawer-up-next-padding);
  width: var(--playlist-drawer-up-next-width);
  text-align: center;
  z-index: 2;
}

.playlist-drawer__active {
  font-size: h.rem(12px);
  line-height: 10px;
  font-family: var(--playlist-drawer-active-font-family);
  background: var(--playlist-drawer-active-background);
  color: var(--playlist-drawer-text-color);
  text-transform: uppercase;
  position: absolute;
  z-index: var(--playlist-drawer-active-z-index);
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: h.rem(20px);
  opacity: var(--playlist-drawer-active-opacity);
  box-sizing: border-box;
  cursor: initial;
  justify-content: center;
  align-items: center;

  .playlist-drawer__title,
  .playlist-drawer__duration {
    opacity: 1;
  }
}

body.think {
  .playlist-drawer__title {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: subpixel-antialiased;
  }
}

body.today {
  .playlist-drawer__video {
    height: var(--playlist-drawer-video-height, auto);
    max-height: unset;

    @include h.breakpoint-l {
      max-height: h.rem(270px);
    }
  }

  .playlist-drawer__title {
    font-weight: var(--normal);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    letter-spacing: 0.5px;

    @include h.line-clamp(4,  23, #000);
  }

  .playlist-drawer__info {
    position: relative;
    margin: h.rem(12px) 0 0;

    @include h.breakpoint-m {
      margin: h.rem(24px) 0 0;
    }

    @include h.breakpoint-l {
      margin: 0;
      padding: 0 0 0 h.rem(16px);
    }
  }

  .playlist-drawer__duration {
    position: absolute;
    top: -130px;
    right: 0;
    transform: translateY(h.rem(-12px));
    background: rgba(0, 0, 0, 0.5);
    font-family: var(--secondary-font);
    color: var(--white);
    font-size: var(--text-12);
    line-height: var(--leading-150);
    display: flex;
    align-items: center;
    height: h.rem(24px);
    padding: h.rem(0 8px);
    z-index: 2;

    @include h.breakpoint-m {
      top: -110px;
      transform: translateY(h.rem(-24px));
    }

    @include h.breakpoint-l {
      top: 0;
      left: 0;
      right: auto;
      transform: translate3d(-100%, 0, 0);
    }
  }
}

.playlist-drawer__video:hover .playlist-drawer__title {
  opacity: var(--playlist-drawer-title-opacity--hover);
  color: var(--playlist-drawer-title-hover-color);
}

.playlist-drawer__video:hover .playlist-drawer__info {
  background-color: var(--playlist-drawer-info-background-color--hover);
}

.playlist-drawer__video:not(.playlist-drawer__active):hover {
  .playlist-drawer__thumbnail {
    transform: none;
  }
}
